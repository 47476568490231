import Debug from 'debug';

import {
  ReactNode,
  useImperativeHandle,
  useRef,
  RefObject,
  useCallback,
  FormEventHandler,
  FormEvent,
} from 'react';

import withSpacing, { WithSpacingProps } from '~/app/lib/hocs/withSpacing';
import { useTracker } from '../../lib/tracker/useTracker';
import useDebounce from '~/app/lib/hooks/useDebounce';

const debug = Debug('songwhip/Form');

export const FORM_SECTION_SPACING = '2.2rem';

export interface FormApi {
  submit: () => void;
  isValid: () => boolean;
}

export interface FormValues {
  [name: string]: string | boolean;
}

export type FormOnSubmit<TValues extends FormValues> = (params: {
  event: FormEvent<HTMLFormElement>;
  values: TValues;
  form: HTMLFormElement;
}) => void;

export type FormOnChange = (params: { isValid: boolean }) => void;

export interface FormProps<TValues extends FormValues>
  extends WithSpacingProps {
  children: ReactNode;
  apiRef?: RefObject<FormApi>;
  onSubmit?: FormOnSubmit<TValues>;
  onChange?: FormOnChange;
  withTracking?: boolean;
  trackingId?: string;
  testId?: string;
  isDisabled?: boolean;
}

const Form = <TValues extends FormValues>({
  children,
  apiRef,
  onSubmit,
  onChange,
  withTracking = true,
  trackingId,
  testId,
  style = {},
  isDisabled,
  ...withSpacingProps
}: FormProps<TValues>) => {
  const formRef = useRef<HTMLFormElement>(null);
  const { trackEvent } = useTracker();

  // expose submit method via apiRef prop
  useImperativeHandle(
    apiRef,
    () => ({
      submit: () => {
        // Trigger the form's onSubmit callback, calling `.submit()` bypasses the callbacks
        // https://github.com/facebook/react/issues/6796#issuecomment-288350025
        formRef.current?.dispatchEvent(
          // submit event is cancelable, so we can prevent the default action like page reloading that happens in Mozilla Firefox
          new Event('submit', { cancelable: true })
        );
      },

      isValid: () => {
        return !!formRef.current?.checkValidity();
      },
    }),
    []
  );

  if (isDisabled) {
    style.pointerEvents = 'none';
    style.opacity = 0.6;
  }

  return (
    <form
      {...withSpacingProps}
      style={style}
      data-testid={testId}
      ref={formRef}
      onInput={useDebounce(
        (event) => {
          debug('on input', event);

          if (onChange) {
            onChange({
              isValid: !!formRef.current?.checkValidity(),
            });
          }
        },
        600,
        []
      )}
      onSubmit={useCallback<FormEventHandler<HTMLFormElement>>(
        (event) => {
          event.preventDefault();

          const form = formRef.current;

          if (!onSubmit || !form) return;

          // Don't continue if any form values are invalid. This
          // also shows any native validation prompts.
          if (!form.reportValidity()) {
            // stop this bubbling to any other listeners
            event.stopPropagation();
            return;
          }

          const values = getValues<TValues>(form);

          if (withTracking) {
            trackEvent({
              type: 'form-submit',
              id: trackingId || undefined,
              data: JSON.stringify(values),
            });
          }

          onSubmit({
            event,
            values,
            form,
          });
        },
        [onSubmit]
      )}
    >
      {/* you can't add `disabled` attr to a <form> */}
      <fieldset
        disabled={isDisabled}
        style={{
          // make this component effectively disappear from layout,
          // we only need it for it's `disabled` attribute
          display: 'contents',
        }}
      >
        {children}
        {/* nested <button> means Enter key on focused input triggers 'submit' */}
        <button type="submit" hidden />
      </fieldset>
    </form>
  );
};

const getValues = <TValues extends FormValues>(form: HTMLFormElement) => {
  const values = {} as TValues;

  const inputs = form.elements;
  let i = inputs.length;

  while (i--) {
    const el = inputs[i];

    if (
      el instanceof HTMLInputElement ||
      el instanceof HTMLTextAreaElement ||
      el instanceof HTMLSelectElement
    ) {
      if (!el.name) continue;

      if (el.type === 'checkbox') {
        (values as FormValues)[el.name] = (el as HTMLInputElement).checked;
      } else {
        (values as FormValues)[el.name] = cleanValue(el.value);
      }
    }
  }

  return values;
};

const cleanValue = (value: string) =>
  value
    .trim()
    // excess space
    .replace(/ +/g, ' ');

const FormWithSpacing = withSpacing(Form);

export default FormWithSpacing as typeof Form;
